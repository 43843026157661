import fetch from 'cross-fetch'
import { defineStore } from 'pinia'
import { fetchStoryblokContent, getTheme } from '../api/storyblok'
import { fetchVacancy, fetchPublications } from '../api/tooContentDelivery'
import { fetchCompany, fetchVacancyList } from '../api/tooPublic.js'
import {
  getVacancyIdFromPath,
  getJobAlertTokenFromPath,
} from '../utils/extractFromPath'

export const useMainStore = defineStore('main', {
  state: () => ({
    domainSlug: '',
    site: {},
    siteLoaded: false,
    theme: null,

    pageContent: null,
    pageContext: null,

    vacancy: null,
    company: null,
    publication: null,

    // Can be used as a badge in links and buttons
    siteVacanciesTotal: 0,
    siteVacanciesFetched: false,

    // Used for A/B testing
    features: {},
  }),
  getters: {
    abTestBlocks: (state) => {
      return (
        state.pageContent?.blocks.filter(
          (block) => block.component === 'ABTest',
        ) || []
      )
    },
    themeVariables: (state) => {
      const legacyThemeVariables = state.theme || state.site?.theme || {}
      const legacyColorPrefixes = [
        '--primary',
        '--secondary',
        '--tertiary',
        '--fourth',
        '--gray',
      ]

      const themeVariables = {}

      let includesLegacyColors = false
      for (const [key, value] of Object.entries(legacyThemeVariables)) {
        if (key.startsWith('--')) {
          // We also use variables for handling fonts, so we need to check if it's a color variable
          const isLegacyColor = legacyColorPrefixes.find((prefix) =>
            key.startsWith(prefix),
          )

          if (
            !isLegacyColor ||
            !legacyThemeVariables?.dontUseDeprecatedColors
          ) {
            themeVariables[key] = value

            if (key.includes('primary-500')) {
              includesLegacyColors = true
              themeVariables['--default'] = value
            }
          }
        }
      }

      state.theme?.palette?.forEach((color) => {
        themeVariables[`--${color.name}`] = color.value

        if (!includesLegacyColors && color.default) {
          themeVariables['--default'] = color.value
          // TODO Improve handling of secondary color
          // A small group of blocks have the secondary color hardcoded for their links/buttons
          themeVariables['--secondary-500'] = color.value
          themeVariables['--secondary-600'] = color.value
        }
      })

      // Add grey scale if it's not defined
      if (!includesLegacyColors) {
        // This colors are from the color scheme of Gemeentebanen, used by default in the previous theme
        themeVariables['--gray-100'] = '#efefef'
        themeVariables['--gray-200'] = '#dfdfdf'
        themeVariables['--gray-300'] = '#d0d0d0'
        themeVariables['--gray-400'] = '#c0c0c0'
        themeVariables['--gray-500'] = '#b0b0b0'
        themeVariables['--gray-600'] = '#8d8d8d'

        if (themeVariables['--dark-text']) {
          themeVariables['--gray-700'] =
            `color-mix(in oklab, ${themeVariables['--dark-text']}, white 20%)`
          themeVariables['--gray-800'] = themeVariables['--dark-text']
          themeVariables['--gray-900'] =
            `color-mix(in oklab, ${themeVariables['--dark-text']}, black 35%)`
        } else {
          themeVariables['--gray-700'] = '#6a6a6a'
          themeVariables['--gray-800'] = '#464646'
          themeVariables['--gray-900'] = '#232323'
        }
      }

      // We add the default colors
      themeVariables['--black'] = '#000000'
      themeVariables['--white'] = '#FFFFFF'
      themeVariables['--transparent'] = 'transparent'

      return themeVariables
    },
  },
  actions: {
    async fetchPageContent(pageContext) {
      let vacancyId = getVacancyIdFromPath(
        pageContext.path,
        this.site?.vacancyLinkFormat,
      )
      const jobAlertToken = getJobAlertTokenFromPath(pageContext.path)
      const isJobAlertUnsubscribePage = !!jobAlertToken

      let fallbackPath = '/error-404'
      if (vacancyId) {
        fallbackPath = '/_default/_vacancy'
      }
      if (isJobAlertUnsubscribePage) {
        fallbackPath = '/_default/_unsubscribe'
      }

      // Remove query params from Storyblok's story path
      const storyPath = pageContext.path.split('?')[0]
      const enhancedContext = {
        ...pageContext,
        path: storyPath,
        domainSlug: this.domainSlug,
        baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
        vacancyId,
        jobAlertToken,
        fallbackPath,
      }

      try {
        let pageContent = await fetchStoryblokContent(enhancedContext)
        const companySlug = pageContent.dataCompanySlug
        if (pageContent.dataVacancyId) {
          vacancyId = pageContent.dataVacancyId
        }
        const { vacancy, company, publication } = await this.fetchTooData({
          vacancyId,
          companySlug,
        })

        // Go to 404 page if we can't find the vacancy and there's a vacancy ID available
        if (vacancyId && !vacancy && fallbackPath !== '/error-404') {
          console.log(
            `Vacancy with ID ${vacancyId} not found, display the 404 page`,
          )
          pageContent = await fetchStoryblokContent({
            ...enhancedContext,
            vacancyId: null,
            fallbackPath: '/error-404',
          })
        }

        // We update the store after all requests are done so we don't trigger multiple changes in the UI
        this.$patch({
          pageContent,
          pageContext: enhancedContext,
          vacancy,
          company,
          publication,
        })
      } catch (e) {
        this.pageContent = null
        this.pageContext = enhancedContext

        throw e
      }
    },
    async fetchSite() {
      if (this.site.name) return

      try {
        const { data } = await checkApiRes(
          await fetch(
            `${import.meta.env.VITE_BASE_TOO_API_URL}/content-delivery/v1/${
              this.domainSlug
            }/sites`,
            {},
          ),
        )

        // theme is provided as a stringified JSON, so we convert it to a JS object
        if (data.theme && typeof data.theme === 'string') {
          data.theme = JSON.parse(data.theme)
        }
        this.site = data
      } catch (err) {
        console.error(err)
      } finally {
        this.siteLoaded = true
      }
    },
    async fetchTheme(params) {
      if (this.theme) return

      const theme = await getTheme({ ...params, domainSlug: this.domainSlug })
      this.theme = theme
    },
    async fetchTooData({ vacancyId, companySlug }) {
      const fetchPromises = []

      let vacancyData,
        companyData,
        publicationData = null

      try {
        if (companySlug) {
          fetchPromises.push(
            fetchCompany({
              companySlug,
              siteId: this.site?.token,
              baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
            }).then((company) => {
              companyData = company
            }),
          )
        }
        if (vacancyId) {
          fetchPromises.push(
            fetchPublications({
              vacancyId,
              domainSlug: this.domainSlug,
              baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
            }).then((publication) => {
              // Normalize publication's end date (to fix possible issues with timezones)
              // Don't do this for ONE vacancies!
              if (
                !publication?.applicationUrl?.includes('vacatures.one') &&
                publication?.endDate &&
                !publication.endDate.endsWith('Z')
              ) {
                const endDate = new Date(`${publication.endDate}Z`)
                // Convert to proper timezone, using Sweden's locale to get the date with format: yyyy-mm-dd
                const tzDateStr = endDate.toLocaleDateString('sv-SE', {
                  timeZone: 'Europe/Amsterdam',
                })
                publication.endDate = `${tzDateStr}T21:59:59.999Z`
              }

              publicationData = publication
            }),
          )

          fetchPromises.push(
            fetchVacancy({
              vacancyId,
              domainSlug: this.domainSlug,
              baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
            }).then((vacancy) => {
              vacancyData = vacancy
              addContactFirstAndLastName(vacancyData)
              formatVacancySalaries(vacancyData, this.theme)

              if (!companySlug) {
                return fetchCompany({
                  companyId: vacancyData.companyId,
                  siteId: this.site?.token,
                  baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
                }).then((company) => {
                  companyData = company
                })
              }
            }),
          )
        }

        const results = await Promise.allSettled(fetchPromises)
        results.forEach((result) => {
          if (result.status === 'rejected') {
            console.error(result.reason)
          }
        })

        return {
          vacancy: vacancyData,
          company: companyData,
          publication: publicationData,
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.siteLoaded = true
      }
    },
    getTotalSiteVacancies() {
      if (this.siteVacanciesFetched) {
        return
      }

      this.siteVacanciesFetched = true
      fetchVacancyList({
        searchQuery: {},
        siteId: this.site?.token,
        baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
      }).then((vacancies) => {
        this.siteVacanciesTotal = vacancies?.length || 0
      })
    },
  },
})

const checkApiRes = async (response) => {
  const data = await response.json()
  // check for error response
  if (!response.ok) {
    // get error message from body or default to response statusText
    const error = (data && data.message) || response.statusText
    return Promise.reject(error)
  }
  return data
}

const addContactFirstAndLastName = (vacancy) => {
  if (vacancy?.contactPerson?.name) {
    const nameParts = vacancy?.contactPerson?.name.split(' ')
    vacancy.contactPerson = {
      ...vacancy.contactPerson,
      firstName: nameParts[0],
      lastName: nameParts.slice(1).join(' '),
    }
  }
}

const formatVacancySalaries = (vacancy, theme) => {
  if (vacancy?.salary) {
    const salary = { ...vacancy.salary }
    const locale = theme?.locale || 'nl'

    salary.minFormatted = !theme?.notFormatCurrency
      ? new Intl.NumberFormat(locale).format(salary.min)
      : salary.min
    salary.maxFormatted = !theme?.notFormatCurrency
      ? new Intl.NumberFormat(locale).format(salary.max)
      : salary.max

    vacancy.salary = salary
  }
}
